import React from "react"
import "./src/styles/global.scss"
import "typeface-rubik"
import "typeface-open-sans"
import "typeface-barlow"
import smoothscroll from "smoothscroll-polyfill"
import SimpleReactLightbox from "simple-react-lightbox"
import TagManager from "react-gtm-module"

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
  }

  if (typeof window !== "undefined") {
    smoothscroll.polyfill()

    const tagManagerArgs = {
      gtmId: "GTM-NVQWF2N",
    }

    TagManager.initialize(tagManagerArgs)
  }
}

// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => (
  <SimpleReactLightbox>{element}</SimpleReactLightbox>
)
